import { authApi, AuthUserResult } from "@/api/auth";
import { defineStore } from "pinia";
import * as Sentry from "@sentry/vue";

export type AuthState = {
    authUser: AuthUserResult | null;
};

export const useAuthStore = defineStore("auth", {
    state: (): AuthState => ({
        authUser: null,
    }),
    actions: {
        async authenticated() {
            const auth = await authApi.authenticated();
            if (auth !== null) {
                this.$patch({ authUser: auth });
                Sentry.setUser({ email: auth.user.email });
            }

            return auth !== null;
        },
        async login(UserName: string, Password: string, RememberMe: boolean) {
            const auth = await authApi.login(UserName, Password, RememberMe);
            if (auth) {
                this.$patch({ authUser: auth });
                Sentry.setUser({ email: auth.user.email });
                return true;
            } else {
                return false;
            }
        },
        async logout() {
            await authApi.logout();
            Sentry.setUser(null);
            this.$state = {
                authUser: null,
            };
        },
    },
});

import {
    Bars3Icon,
    ChevronDownIcon,
    CodeBracketIcon,
    ServerIcon,
    UserGroupIcon,
    XMarkIcon,
    CheckIcon,
    EnvelopeIcon,
    PhoneIcon,
    MegaphoneIcon,
    ArrowRightIcon,
    CheckCircleIcon,
    ArrowsUpDownIcon,
    UserCircleIcon,
    KeyIcon,
    ArrowRightOnRectangleIcon,
    Squares2X2Icon,
    ArrowLongLeftIcon,
    ArrowLongRightIcon,
    ChevronRightIcon,
    BanknotesIcon,
    ClipboardIcon,
    ChevronLeftIcon,
    BookOpenIcon,
} from "@heroicons/vue/24/outline";
import { App } from "vue";
export default function registerComponents(app: App) {
    app.component("MenuIcon", Bars3Icon);
    app.component("ChevronDownIcon", ChevronDownIcon);
    app.component("CodeIcon", CodeBracketIcon);
    app.component("ServerIcon", ServerIcon);
    app.component("UserGroupIcon", UserGroupIcon);
    app.component("XIcon", XMarkIcon);
    app.component("CheckIcon", CheckIcon);
    app.component("MailIcon", EnvelopeIcon);
    app.component("PhoneIcon", PhoneIcon);
    app.component("SpeakerphoneIcon", MegaphoneIcon);
    app.component("ArrowRightIcon", ArrowRightIcon);
    app.component("SuccessIcon", CheckCircleIcon);
    app.component("UpDownIcon", ArrowsUpDownIcon);
    app.component("UserCircleIcon", UserCircleIcon);
    app.component("KeyIcon", KeyIcon);
    app.component("LogoutIcon", ArrowRightOnRectangleIcon);
    app.component("GridIcon", Squares2X2Icon);
    app.component("ChevronRightIcon", ChevronRightIcon);
    app.component("ArrowLongLeftIcon", ArrowLongLeftIcon);
    app.component("ArrowLongRightIcon", ArrowLongRightIcon);
    app.component("BanknotesIcon", BanknotesIcon);
    app.component("ClipboardIcon", ClipboardIcon);
    app.component("ChevronLeftIcon", ChevronLeftIcon);
    app.component("BookOpenIcon", BookOpenIcon);
}

import { createApp } from "vue";
import App from "./app.vue";
import router from "./router";
import registerComponents from "./components";
import registerIcons from "./plugins/heroicons";
import registerLayout from "./layout";
import TawkMessengerVue from "@tawk.to/tawk-messenger-vue-3";
import Analytics from "./modules/analytics";
import VueGTag from "vue-gtag";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { createPinia } from "pinia";
import { useAuthStore } from "./store/auth";
import * as Sentry from "@sentry/vue";

import "./registerServiceWorker";
import { sentryApi } from "./api/sentry";

(async function () {
    const pinia = createPinia();

    const authStore = useAuthStore(pinia);

    await authStore.authenticated();

    const app = createApp(App);
    const sentryConfig = await sentryApi.get();
    Sentry.init({
        app,
        dsn: sentryConfig.dsn,
        tunnel: "/tunnel",
        release: sentryConfig.release,
        environment: sentryConfig.environment,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ],
        enabled: true,
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        debug: sentryConfig.debug
    });
    Sentry.setUser({ ip: sentryConfig.ip });

    registerComponents(app);
    registerIcons(app);
    registerLayout(app);
    app.use(router)
        .use(pinia)
        .use(VueReCaptcha, { siteKey: "6Le0MYsjAAAAAKwo_W5RGjuMzqcNKwCAMZhGr1vo", loaderOptions: {} })
        .use(VueGTag, { config: { id: Analytics.getTrackingCode(window.location.hostname) as string } })
        .use(TawkMessengerVue, {
            propertyId: "59ee3b554854b82732ff73d2",
            widgetId: "default",
        })
        .mount("#app");
})();

import { App } from "vue";
import FeatureList from "./featurelist.vue";
import Hero from "./hero.vue";
import Story from "./story.vue";
import Loading from "./loading.vue";
import UserMenu from "./userMenu.vue";
export default function registerComponents(app: App) {
    app.component("FeatureList", FeatureList);
    app.component("Hero", Hero);
    app.component("Story", Story);
    app.component("Loading", Loading);
    app.component("UserMenu", UserMenu);
}


import axios, { AxiosError } from "@/plugins/axios";

export type User = {
    id: string;
    userName: string;
    email: string;
    firstName: string | null;
    lastName: string | null;
    emailConfirmed: boolean;
    lockoutEnabled: boolean;
    lockoutEnd: string | null;
    phoneNumber: string;
};

export type AuthUserResult = {
    isAdmin: boolean;
    isAuthenticated: boolean;
    user: User;
    roles: string[];
    claims: string[];
};

export type AuthApi = {
    authenticated(): Promise<AuthUserResult | null>;
    login(userName: string, password: string, rememberMe: boolean): Promise<AuthUserResult | null>;
    logout(): Promise<void>;
    forgot(userName: string): Promise<void>;
    reset(userName: string, token: string, password: string): Promise<void>;
};

export const authApi: AuthApi = {
    async authenticated() {
        try {
            const { data: user } = await axios.get<AuthUserResult>("auth/isauthorized");
            return user;
        } catch (ex: any) {
            if (ex instanceof AxiosError && ex.response?.status === 403) {
                return null;
            } else {
                throw ex;
            }
        }
    },
    async login(userName, password, rememberMe) {
        try {
            const { data: user } = await axios.post<AuthUserResult>("auth/login", {
                userName,
                password,
                rememberMe,
            });
            return user;
        } catch (ex: any) {
            if (ex instanceof AxiosError && ex.response?.status === 400) {
                return null;
            } else {
                throw ex;
            }
        }
    },
    async logout() {
        await axios.post("auth/logout");
    },
    async forgot(userName) {
        await axios.post("auth/forgot", { userName });
    },
    async reset(userName, token, password) {
        await axios.post("auth/reset", { userName, token, password });
    },
};

interface Analytics {
    getTrackingCode(hostname: string): string | null;
}

const Analytics: Analytics = {
    getTrackingCode(hostname) {
        switch (hostname) {
            case "roushtech.net":
                return "";
            case "qa.roushtech.net":
                return "";
            default:
                return null;
        }
    },
};

export default Analytics;


import axios from "@/plugins/axios";
import { useAuthStore } from "@/store/auth";
import { defineComponent, ref, computed, watchEffect, watch } from "vue";

type UserSubMenuItem = {
    name: string;
    to: string;
    icon: string;
};

type UserMenuItem = {
    name: string;
    to?: string;
    function?: void | (() => Promise<void>);
    icon: string;
    submenu?: UserSubMenuItem[];
};

type UserMenu = {
    menuOpen: boolean;
    data: UserMenuItem[];
};

export default defineComponent({
    async setup() {
        const store = useAuthStore();
        const login = computed(() => {
            return store.authUser?.isAuthenticated;
        });
        const userId = computed(() => {
            return store.authUser?.user.id;
        });
        const isAdmin = computed(() => {
            return store.authUser?.isAdmin;
        });
        const submenuOpen = ref(false);
        const menu = ref<UserMenu>({
            menuOpen: false,
            data: [
                {
                    name: "My page",
                    to: `/user/${userId.value}`,
                    icon: "UserCircleIcon",
                },
                {
                    name: "License Management",
                    to: "/licenses",
                    icon: "KeyIcon",
                },
                {
                    name: "Purchase History",
                    to: "/purchases",
                    icon: "BanknotesIcon",
                },
                {
                    name: "Log out",
                    function: logout,
                    icon: "LogoutIcon",
                },
            ],
        });

        watchEffect(() => {
            if (login.value) {
                if (menu.value.data[0]) menu.value.data[0].to = `/user/${userId.value}`;
            }
            if (isAdmin.value) {
                menu.value.data.splice(1, 0, {
                    name: "Admin Panel",
                    submenu: [
                        { name: "User List", icon: "ClipboardIcon", to: "/admin/users" },
                        { name: "Articles", icon: "BookOpenIcon", to: "/admin/articles" },
                    ],
                    icon: "ChevronLeftIcon",
                });
            }
            if (!isAdmin.value && menu.value.data[1].name === "Admin Panel") {
                menu.value.data.splice(1, 1);
            }
        });

        watch(menu.value, () => {
            submenuOpen.value = false;
        });

        async function logout() {
            menu.value.menuOpen = false;
            await store.logout().catch(function (error) {
                console.log(error);
            });
        }

        return {
            login,
            menu,
            logout,
            submenuOpen,
        };
    },
});

import axios, { AxiosError, AxiosResponse } from "axios";

axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
        alert(error);
    }
);

export default axios.create({
    baseURL: "/api/v0/",
    headers: {
        common: {
            Accept: "application/json",
        },
    },
});

export { AxiosError };


import { defineComponent, PropType } from "vue";

interface Feature {
    title: string;
    about: string;
}

export default defineComponent({
    props: {
        title: String,
        description: String,
        items: Array as PropType<Feature[]>,
        columns: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        return {
            props,
        };
    },
});

import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative bg-white overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.heroImage) + " ", 1),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "intro")
    ])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Transition as _Transition, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "relative ml-0" }
const _hoisted_2 = { class: "absolute z-20 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 ml-0 md:left-full md:-translate-x-full left-1/2 -translate-x-3/4" }
const _hoisted_3 = { class: "rounded-lg shadow-lg ring-1 ring-black ring-opacity-5" }
const _hoisted_4 = { class: "ml-4" }
const _hoisted_5 = { class: "text-base font-medium text-gray-900" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "ml-4" }
const _hoisted_8 = { class: "text-base font-medium text-gray-900" }
const _hoisted_9 = { class: "ml-4" }
const _hoisted_10 = { class: "text-base font-medium text-gray-900" }
const _hoisted_11 = { class: "absolute z-1000 -translate-y-8 -translate-x-2.5 -ml-4 px-2 w-screen max-w-md sm:px-0 ml-0 -left-full" }
const _hoisted_12 = { class: "rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden" }
const _hoisted_13 = { class: "ml-4" }
const _hoisted_14 = { class: "text-base font-medium text-gray-900" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserCircleIcon = _resolveComponent("UserCircleIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_GridIcon = _resolveComponent("GridIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.login)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: "/login",
          class: "h-3 w-3 overflow-hidden cursor-pointer rounded-full"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_UserCircleIcon, { class: "h-10 w-10 text-roushtech-blue" })
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock("button", {
          key: 1,
          type: "button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.menu.menuOpen = !_ctx.menu.menuOpen)),
          class: _normalizeClass([{ 'text-gray-900': _ctx.menu.menuOpen }, "group bg-white rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 gap-1 items-center focus:ring-offset-2 focus:ring-indigo-500"])
        }, [
          _createTextVNode(" Menu "),
          _createVNode(_component_GridIcon, { class: "ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500" })
        ], 2)),
    _withDirectives(_createVNode(_Transition, {
      "enter-active-class": "transition ease-out duration-200",
      "leave-active-class": "transition ease-in duration-150",
      "enter-class": "opacity-0 translate-y-1",
      "enter-to-class": "opacity-100 translate-y-0",
      "leave-class": "opacity-100 translate-y-0",
      "leave-to-class": "opacity-0 translate-y-1"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.data, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item?.name,
                class: "relative cursor-pointer select-none grid bg-white px-5 gap-8 p-8"
              }, [
                (item && item?.to)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.menu.menuOpen = false)),
                      to: item.to,
                      class: "-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), { class: "w-6" })),
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("p", _hoisted_5, _toDisplayString(item.name), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (item?.function)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        onClick: ($event: any) => {
                                item.function;
                                _ctx.menu.menuOpen = false;
                            },
                        class: "-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                      }, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), { class: "w-6" })),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("p", _hoisted_8, _toDisplayString(item.name), 1)
                        ])
                      ], 8, _hoisted_6))
                    : (item?.submenu)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 2,
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submenuOpen = !_ctx.submenuOpen)),
                          class: "relative -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                        }, [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), { class: "w-6" })),
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("p", _hoisted_10, _toDisplayString(item.name), 1)
                          ]),
                          _withDirectives(_createVNode(_Transition, {
                            "enter-active-class": "transition ease-out duration-200",
                            "leave-active-class": "transition ease-in duration-150",
                            "enter-class": "opacity-0 translate-y-1",
                            "enter-to-class": "opacity-100 translate-y-0",
                            "leave-class": "opacity-100 translate-y-0",
                            "leave-to-class": "opacity-0 translate-y-1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("div", _hoisted_12, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.submenu, (subItem) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: subItem?.name,
                                      class: "cursor-pointer select-none grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8"
                                    }, [
                                      _createVNode(_component_router_link, {
                                        onClick: _cache[2] || (_cache[2] = ($event: any) => {
                                                    _ctx.menu.menuOpen = false;
                                                    _ctx.submenuOpen = false;
                                                }),
                                        to: subItem.to,
                                        class: "-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(), _createBlock(_resolveDynamicComponent(subItem.icon), { class: "w-6" })),
                                          _createElementVNode("div", _hoisted_13, [
                                            _createElementVNode("p", _hoisted_14, _toDisplayString(subItem.name), 1)
                                          ])
                                        ]),
                                        _: 2
                                      }, 1032, ["to"])
                                    ]))
                                  }), 128))
                                ])
                              ])
                            ]),
                            _: 2
                          }, 1536), [
                            [_vShow, _ctx.submenuOpen]
                          ])
                        ]))
                      : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.menu.menuOpen]
    ])
  ]))
}
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/home/index.vue"),
    },
    {
        path: "/stories",
        name: "stories",
        component: () => import("@/views/home/stories.vue"),
    },
    {
        path: "/contact",
        name: "contact",
        component: () => import("@/views/home/contact.vue"),
    },
    {
        path: "/solutions/infrastructure",
        name: "infrastructure",
        component: () => import("@/views/solutions/infrastructure.vue"),
    },
    {
        path: "/solutions/management",
        name: "management",
        component: () => import("@/views/solutions/management.vue"),
    },
    {
        path: "/solutions/software",
        name: "software",
        component: () => import("@/views/solutions/software.vue"),
    },
    {
        path: "/portfolio",
        name: "portfolio",
        component: () => import("@/views/portfolio/index.vue"),
    },
    {
        path: "/articles/:slug",
        name: "articles",
        component: () => import("@/views/articles/index.vue"),
    },
    {
        path: "/admin/articles/:id",
        name: "editArticles",
        component: () => import("@/views/admin/adminArticleEdit.vue"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/signIn-signUp/signIn.vue")
    },
    {
        path: "/signUp",
        name: "signUp",
        component: () => import("@/views/signIn-signUp/signUp.vue")
    },
    {
        path: "/auth/reset",
        name: "restorePassword",
        props: (route) => ({ token: route.query["token"] }),
        component: () => import("@/views/signIn-signUp/restorePassword.vue")
    },
    {
        path: "/licenses",
        name: "licenses",
        component: () => import("@/views/home/licenseManagement.vue"),
    },
    {
        path: "/user/:id",
        name: "user",
        component: () => import("@/views/user/userPage.vue"),
    },
    {
        path: "/history",
        name: "history",
        component: () => import("@/views/home/purchaseHistory.vue"),

    },
    {
        path: "/articles",
        name: "articlesList",
        component: () => import("@/views/articles/articlesList.vue"),
    },
    {
        path: "/purchases",
        name: "purchaseHistory",
        component: () => import("@/views/home/purchaseHistory.vue")
    },
    {
        name: "AuthWelcome",
        path: "/welcome",
        props: (route) => ({ userId: route.query["user"], token: route.query["token"] }),
        component: () =>
            import("@/views/signIn-signUp/welcome.vue"),
    },
    {
        name: "adminUsers",
        path: "/admin/users",
        component: () =>
            import("@/views/admin/adminUsers.vue"),
    },
    {
        name: "adminEditUser",
        path: "/admin/users/:id",
        component: () =>
            import("@/views/admin/adminUserEdit.vue"),
    },
    {
        name: "adminAddUser",
        path: "/admin/add-user",
        component: () =>
            import("@/views/admin/adminCreateUser.vue"),
    },
    {
        name: "adminArticles",
        path: "/admin/articles",
        component: () =>
            import("@/views/admin/adminArticles.vue"),
    },
    {
        name: "addArticle",
        path: "/admin/articles/add",
        component: () =>
            import("@/views/admin/adminArticleAdd.vue"),
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;

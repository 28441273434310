
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        title: String,
        highlightTitle: String,
        description: String,
        image: String,
        story: String,
    },
});

import axios from "@/plugins/axios";

export type SentryConfig = {
    dsn: string;
    release: string;
    environment: string;
    debug: boolean;
    ip: string;
};

export type SentryApi = {
    get(): Promise<SentryConfig>
};

export const sentryApi: SentryApi = {
    async get() {
        const response = await axios.get<SentryConfig>("sentry");
        return response.data;
    },
};

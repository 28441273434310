import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8" }
const _hoisted_3 = { class: "max-w-3xl mx-auto text-center" }
const _hoisted_4 = { class: "text-3xl font-extrabold text-gray-900" }
const _hoisted_5 = { class: "mt-4 text-lg text-gray-500" }
const _hoisted_6 = { class: "ml-3" }
const _hoisted_7 = { class: "text-lg leading-6 font-medium text-gray-900" }
const _hoisted_8 = { class: "mt-2 text-base text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckIcon = _resolveComponent("CheckIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.description), 1)
      ]),
      _createElementVNode("dl", {
        class: _normalizeClass(["mt-12 space-y-10 sm:space-y-0 sm:grid sm:gap-x-6 sm:gap-y-12 lg:gap-x-8", `sm:grid-cols-${_ctx.columns / 2} lg:grid-cols-${_ctx.columns}`])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "flex",
            key: item.title
          }, [
            _createVNode(_component_CheckIcon, { class: "flex-shrink-0 h-6 w-6 text-green-500" }),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("dt", _hoisted_7, _toDisplayString(item.title), 1),
              _createElementVNode("dd", _hoisted_8, _toDisplayString(item.about), 1)
            ])
          ]))
        }), 128))
      ], 2)
    ])
  ]))
}